const productData = [
  {
    id: 1,
    name: "【24AW】 STAR JAQ SNEAD / バックロゴ スター ジャック スニード （グリーン）",
    main: "V12",
    description: [
      "首元のジャガードのスターがV12らしさ全開のスニード",
      "裾もジャガードリブ仕様になりよりアクティブな印象に。",
      "バックにはブランドロゴを大きく配置しどこからみても抜かりない仕上がり。",
      "これまでにないカラー展開も新鮮で色違いで持っておきたいアイテム。",
      "軽量かつ防風仕様なので、寒い日のゴルフでも快適にプレー可能。",
      "品切れを起こしている商品に関しましては、メーカー取り寄せによる販売が可能な場合もございます。",
      "お取り寄せをご希望の場合は、お気軽にお問い合わせ下さい。",
      "サイズ選びに不安のある方は、身長や体重等をお伺いしてアドバイスさせて頂きますので、お気軽にお問い合わせ下さい。",
      "万が一、ご購入頂きました商品のサイズが合わない場合はサイズ交換、またはご返品が可能です。",
      "お気軽にご利用下さいませ。",
      "※サイズ交換、またはご返品がお受け出来ない商品もありますので、事前にご相談下さい。",
    ],
    price: "33000",
    cent: "300",
    sizeImg: "/gossi/size01.png",
    size: ["M（残り1点）", "L（残り1点）", "XL（残り1点）"],
    color: "グリーン",
    material: "",
    materialOuter: "",
    materialSeparate: "",
    materialSynthetic: "",
    materialBody: "ポリエステル100%",
    materialLining: "ポリエステル100%",
    materialRibs: "ポリエステル94% ポリウレタン6%",
    brand: "V12",
    itemNo: "V122420-JK13-GR",
    catagory: "メンズウェア > ジャケット・アウター",
    gender: "男性",
    information: "172cm 64kg(胸囲86cm ウエスト82cm)、着用サイズ：M",
    return: "返品可能条件はこちら",
    wrapping: "可能",
    shipDate: "1-3 business days",
    images: ["/gossi/1001.jpg", "/gossi/1001a.jpg"],
  },
  {
    id: 2,
    name: "【24AW】 STAR JAQ SNEAD / バックロゴ スター ジャック スニード （ブラウン）",
    main: "V12",
    description: [
      "首元のジャガードのスターがV12らしさ全開のスニード",
      "裾もジャガードリブ仕様になりよりアクティブな印象に。",
      "バックにはブランドロゴを大きく配置しどこからみても抜かりない仕上がり。",
      "これまでにないカラー展開も新鮮で色違いで持っておきたいアイテム。",
      "軽量かつ防風仕様なので、寒い日のゴルフでも快適にプレー可能。",
      "品切れを起こしている商品に関しましては、メーカー取り寄せによる販売が可能な場合もございます。",
      "お取り寄せをご希望の場合は、お気軽にお問い合わせ下さい。",
      "サイズ選びに不安のある方は、身長や体重等をお伺いしてアドバイスさせて頂きますので、お気軽にお問い合わせ下さい。",
      "万が一、ご購入頂きました商品のサイズが合わない場合はサイズ交換、またはご返品が可能です。",
      "お気軽にご利用下さいませ。",
      "※サイズ交換、またはご返品がお受け出来ない商品もありますので、事前にご相談下さい。",
    ],
    price: "33000",
    cent: "300",
    sizeImg: "/gossi/size02.png",
    size: ["M（残り1点）", "L（残り1点）", "XL（残り1点）"],
    color: "ブラウン",
    material: "",
    materialOuter: "",
    materialSeparate: "",
    materialSynthetic: "",
    materialBody: "ポリエステル100%",
    materialLining: "ポリエステル100%",
    materialRibs: "ポリエステル94% ポリウレタン6%",
    brand: "V12",
    itemNo: "V122420-JK13-BR",
    catagory: "メンズウェア > ジャケット・アウター",
    gender: "男性",
    information: "172cm 64kg(胸囲86cm ウエスト82cm)、着用サイズ：M",
    return: "返品可能条件はこちら",
    wrapping: "可能",
    shipDate: "1-3営業日",
    images: ["/gossi/1002.jpg", "/gossi/1002a.jpg"],
  },
  {
    id: 3,
    name: "【24AW】 STAR JAQ SNEAD / バックロゴ スター ジャック スニード （ブラック）",
    main: "V12",
    description: [
      "首元のジャガードのスターがV12らしさ全開のスニード",
      "裾もジャガードリブ仕様になりよりアクティブな印象に。",
      "バックにはブランドロゴを大きく配置しどこからみても抜かりない仕上がり。",
      "これまでにないカラー展開も新鮮で色違いで持っておきたいアイテム。",
      "軽量かつ防風仕様なので、寒い日のゴルフでも快適にプレー可能。",
      "品切れを起こしている商品に関しましては、メーカー取り寄せによる販売が可能な場合もございます。",
      "お取り寄せをご希望の場合は、お気軽にお問い合わせ下さい。",
      "サイズ選びに不安のある方は、身長や体重等をお伺いしてアドバイスさせて頂きますので、お気軽にお問い合わせ下さい。",
      "万が一、ご購入頂きました商品のサイズが合わない場合はサイズ交換、またはご返品が可能です。",
      "お気軽にご利用下さいませ。",
      "※サイズ交換、またはご返品がお受け出来ない商品もありますので、事前にご相談下さい。",
    ],
    price: "33000",
    cent: "300",
    sizeImg: "/gossi/size03.png",
    size: ["M（残り1点）", "L（残り1点）", "XL（残り1点）"],
    color: "ブラック",
    material: "",
    materialOuter: "",
    materialSeparate: "",
    materialSynthetic: "",
    materialBody: "ポリエステル100%",
    materialLining: "ポリエステル100%",
    materialRibs: "ポリエステル94% ポリウレタン6%",
    brand: "V12",
    itemNo: "V122420-JK13-BK",
    catagory: "メンズウェア > ジャケット・アウター",
    gender: "男性",
    information: "172cm 64kg(胸囲86cm ウエスト82cm)、着用サイズ：M",
    return: "返品可能条件はこちら",
    wrapping: "可能",
    shipDate: "1-3営業日",
    images: ["/gossi/1003.jpg", "/gossi/1003a.jpg"],
  },
  {
    id: 4,
    name: "【24AW】 SYNAPCE QUILTED HYBRID TAPERED PANTS / キルティング ハイブリッド パンツ (ブラック)",
    main: "MARK&LONA",
    description: [
      "ヒップまわりにゆとりをもたせ、裾に向けて絞りを加えたテーパードシルエット。",
      "フロントのキルティング部分は超軽量・超極薄の防風コーティング素材で撥水性とストレッチ性にも優れた高機能素材。",
      "中綿には人工ダウンと呼ばれる高い保温性と耐久性を兼ね備えた【Primaloft®プリマロフト】を使用。",
      "また後身とフロントレグ部分の内側は毛足の長いフリース素材。",
      "さらにサイド、インシーム、膝部分にはピケ組織のハイテンションニット起毛素材を使用したハイブリッドパンツ。",
      "パンツ全体の暖かさをキープし、全方位に伸縮する快適な着心地。",
      "品切れを起こしている商品に関しましては、メーカー取り寄せによる販売が可能な場合もございます。",
      "お取り寄せをご希望の場合は、お気軽にお問い合わせ下さい。",
      "サイズ選びに不安のある方は、身長や体重等をお伺いしてアドバイスさせて頂きますので、お気軽にお問い合わせ下さい。",
      "万が一、ご購入頂きました商品のサイズが合わない場合はサイズ交換、またはご返品が可能です。",
      "お気軽にご利用下さいませ。",
      "※サイズ交換、またはご返品がお受け出来ない商品もありますので、事前にご相談下さい。",
    ],
    price: "58300",
    cent: "530",
    sizeImg: "/gossi/size04.png",
    size: ["46(M)（在庫あり）", "48(L)（在庫あり）", "50(XL)（在庫あり）"],
    color: "ブラック",
    material: "",
    materialOuter: "",
    materialSeparate: "",
    materialSynthetic: "",
    materialBody: "ポリエステル94% ポリウレタン6%",
    materialLining: "ポリエステル100%",
    materialRibs: "ナイロン63% ポリエステル28% ポリウレタン9%",
    brand: "MARK&LONA",
    itemNo: "MLM-4C-AT11-BK",
    catagory: "メンズウェア > ロングパンツ",
    gender: "男性",
    information: "",
    return: "返品可能条件はこちら",
    wrapping: "可能",
    shipDate: "1-3営業日",
    images: ["/gossi/1004.jpg", "/gossi/1004a.jpg"],
  },
  {
    id: 5,
    name: "【24AW】 SYNAPCE QUILTED HYBRID TAPERED PANTS / キルティング ハイブリッド パンツ (ホワイト)",
    main: "MARK&LONA",
    description: [
      "ヒップまわりにゆとりをもたせ、裾に向けて絞りを加えたテーパードシルエット。",
      "フロントのキルティング部分は超軽量・超極薄の防風コーティング素材で撥水性とストレッチ性にも優れた高機能素材。",
      "中綿には人工ダウンと呼ばれる高い保温性と耐久性を兼ね備えた【Primaloft®プリマロフト】を使用。",
      "また後身とフロントレグ部分の内側は毛足の長いフリース素材。",
      "さらにサイド、インシーム、膝部分にはピケ組織のハイテンションニット起毛素材を使用したハイブリッドパンツ。",
      "パンツ全体の暖かさをキープし、全方位に伸縮する快適な着心地。",
      "品切れを起こしている商品に関しましては、メーカー取り寄せによる販売が可能な場合もございます。",
      "お取り寄せをご希望の場合は、お気軽にお問い合わせ下さい。",
      "サイズ選びに不安のある方は、身長や体重等をお伺いしてアドバイスさせて頂きますので、お気軽にお問い合わせ下さい。",
      "万が一、ご購入頂きました商品のサイズが合わない場合はサイズ交換、またはご返品が可能です。",
      "お気軽にご利用下さいませ。",
      "※サイズ交換、またはご返品がお受け出来ない商品もありますので、事前にご相談下さい。",
    ],
    price: "58300",
    cent: "530",
    sizeImg: "/gossi/size05.png",
    size: ["46(M)（残り1点）", "48(L)（残り1点）", "50(XL)（残り1点）"],
    color: "ホワイト",
    material: "",
    materialOuter: "",
    materialSeparate: "",
    materialSynthetic: "",
    materialBody: "ポリエステル94% ポリウレタン6%",
    materialLining: "ポリエステル100%",
    materialRibs: "ナイロン63% ポリエステル28% ポリウレタン9%",
    brand: "MARK&LONA",
    itemNo: "MLM-4C-AT11-WH",
    catagory: "メンズウェア > ロングパンツ",
    gender: "男性",
    information: "",
    return: "返品可能条件はこちら",
    wrapping: "可能",
    shipDate: "1-3営業日",
    images: ["/gossi/1005.jpg", "/gossi/1005a.jpg"],
  },
  {
    id: 6,
    name: "【24AW】【レディース】 STAR JAQ SNEAD/ バックロゴ スター ジャック スニード (グリーン)",
    main: "V12",
    description: [
      "裾もジャガードリブ仕様になりよりアクティブな印象に。",
      "バックにはブランドロゴを大きく配置しどこからみても抜かりない仕上がり。",
      "これまでにないカラー展開も新鮮で色違いで持っておきたいアイテム。",
      "軽量かつ防風仕様なので、寒い日のゴルフでも快適にプレー可能。",
      "品切れを起こしている商品に関しましては、メーカー取り寄せによる販売が可能な場合もございます。",
      "お取り寄せをご希望の場合は、お気軽にお問い合わせ下さい。",
      "サイズ選びに不安のある方は、身長や体重等をお伺いしてアドバイスさせて頂きますので、お気軽にお問い合わせ下さい。",
      "万が一、ご購入頂きました商品のサイズが合わない場合はサイズ交換、またはご返品が可能です。",
      "お気軽にご利用下さいませ。",
      "※サイズ交換、またはご返品がお受け出来ない商品もありますので、事前にご相談下さい。",
    ],
    price: "33000",
    cent: "300",
    sizeImg: "/gossi/size06.png",
    size: ["1（残り1点）"],
    color: "グリーン",
    material: "",
    materialOuter: "",
    materialSeparate: "",
    materialSynthetic: "",
    materialBody: "ポリエステル100%",
    materialLining: "ポリエステル100%",
    materialRibs: "ポリエステル94% ポリウレタン6%",
    brand: "V12",
    itemNo: "V122420-JK13-GR",
    catagory: "レディースウェア > ジャケット・アウター",
    gender: "女性",
    information: "",
    return: "返品可能条件はこちら",
    wrapping: "可能",
    shipDate: "1-3営業日",
    images: ["/gossi/1006.jpg", "/gossi/1006a.jpg"],
  },
  {
    id: 7,
    name: "【24AW】【レディース】 STAR JAQ SNEAD/ バックロゴ スター ジャック スニード (ブラウン)",
    main: "V12",
    description: [
      "裾もジャガードリブ仕様になりよりアクティブな印象に。",
      "バックにはブランドロゴを大きく配置しどこからみても抜かりない仕上がり。",
      "これまでにないカラー展開も新鮮で色違いで持っておきたいアイテム。",
      "軽量かつ防風仕様なので、寒い日のゴルフでも快適にプレー可能。",
      "品切れを起こしている商品に関しましては、メーカー取り寄せによる販売が可能な場合もございます。",
      "お取り寄せをご希望の場合は、お気軽にお問い合わせ下さい。",
      "サイズ選びに不安のある方は、身長や体重等をお伺いしてアドバイスさせて頂きますので、お気軽にお問い合わせ下さい。",
      "万が一、ご購入頂きました商品のサイズが合わない場合はサイズ交換、またはご返品が可能です。",
      "お気軽にご利用下さいませ。",
      "※サイズ交換、またはご返品がお受け出来ない商品もありますので、事前にご相談下さい。",
    ],
    price: "33000",
    cent: "300",
    sizeImg: "/gossi/size07.png",
    size: ["1（残り1点）"],
    color: "ブラウン",
    material: "",
    materialOuter: "",
    materialSeparate: "",
    materialSynthetic: "",
    materialBody: "ポリエステル100%",
    materialLining: "ポリエステル100%",
    materialRibs: "ポリエステル94% ポリウレタン6%",
    brand: "V12",
    itemNo: "V122420-JK13-BR",
    catagory: "レディースウェア > ジャケット・アウター",
    gender: "女性",
    information: "",
    return: "返品可能条件はこちら",
    wrapping: "可能",
    shipDate: "1-3営業日",
    images: ["/gossi/1007.jpg", "/gossi/1007a.jpg"],
  },
  {
    id: 8,
    name: "【24AW】【レディース】 STAR JAQ SNEAD/ バックロゴ スター ジャック スニード (ブラック)",
    main: "V12",
    description: [
      "裾もジャガードリブ仕様になりよりアクティブな印象に。",
      "バックにはブランドロゴを大きく配置しどこからみても抜かりない仕上がり。",
      "これまでにないカラー展開も新鮮で色違いで持っておきたいアイテム。",
      "軽量かつ防風仕様なので、寒い日のゴルフでも快適にプレー可能。",
      "品切れを起こしている商品に関しましては、メーカー取り寄せによる販売が可能な場合もございます。",
      "お取り寄せをご希望の場合は、お気軽にお問い合わせ下さい。",
      "サイズ選びに不安のある方は、身長や体重等をお伺いしてアドバイスさせて頂きますので、お気軽にお問い合わせ下さい。",
      "万が一、ご購入頂きました商品のサイズが合わない場合はサイズ交換、またはご返品が可能です。",
      "お気軽にご利用下さいませ。",
      "※サイズ交換、またはご返品がお受け出来ない商品もありますので、事前にご相談下さい。",
    ],
    price: "33000",
    cent: "300",
    sizeImg: "/gossi/size08.png",
    size: ["1（残り1点）"],
    color: "ブラック",
    material: "",
    materialOuter: "",
    materialSeparate: "",
    materialSynthetic: "",
    materialBody: "ポリエステル100%",
    materialLining: "ポリエステル100%",
    materialRibs: "ポリエステル94% ポリウレタン6%",
    brand: "V12",
    itemNo: "V122420-JK13-BK",
    catagory: "レディースウェア > ジャケット・アウター",
    gender: "女性",
    information: "",
    return: "返品可能条件はこちら",
    wrapping: "可能",
    shipDate: "1-3営業日",
    images: ["/gossi/1008.jpg", "/gossi/1008a.jpg"],
  },
  {
    id: 9,
    name: "【24AW】【レディース】 1PIU1UGUALE3 HOUNDSTOOTH MOCK / 113総柄ロゴ ハウンドトゥース ロングスリーブ モックネック Tシャツ (ホワイト)",
    main: "1PIU1UGUALE3 GOLF",
    description: [
      "1PIU1UGUALE3が提案するGOLF LINE",
      "日本から世界に向けて発信するブランドとして世界中の上質な素材を贅沢に使用しラグジュアリーな商品をリリースし続ける1PIU1UGUALE3。",
      "ハイエンドラグジュアリーブランドが提案する高いデザイン性とスポーツの機能美を併せ持ち上質を知る全てのプレイヤーの為のウェアとしてリリース致します。革新的なハイテク素材を採用し、ただ派手な物ではなくテーラーリングを得意とする同ブランドならではの立体パターンにより洗練された高いデザイン性と最高のフィッティングを兼ね備え着る者全てに高揚感と優越感をもたらします。",
      "品切れを起こしている商品に関しましては、メーカー取り寄せによる販売が可能な場合もございます。",
      "お取り寄せをご希望の場合は、お気軽にお問い合わせ下さい。",
      "サイズ選びに不安のある方は、身長や体重等をお伺いしてアドバイスさせて頂きますので、お気軽にお問い合わせ下さい。",
      "万が一、ご購入頂きました商品のサイズが合わない場合はサイズ交換、またはご返品が可能です。",
      "お気軽にご利用下さいませ。",
      "※サイズ交換、またはご返品がお受け出来ない商品もありますので、事前にご相談下さい。",
    ],
    price: "45100",
    cent: "410",
    sizeImg: "/gossi/size09.png",
    size: [
      "1（在庫なし/再販売通知リクエスト可）",
      "2（残り1点）",
      "3（在庫なし/再販売通知リクエスト可）",
    ],
    color: "ホワイト",
    material: "ポリエステル100%",
    materialOuter: "",
    materialSeparate: "",
    materialSynthetic: "",
    materialBody: "",
    materialLining: "",
    materialRibs: "",
    brand: "1PIU1UGUALE3 GOLF",
    itemNo: "GST205-POE518-WH10",
    catagory: "レディースウェア > Tシャツ・カットソー",
    gender: "女性",
    information: "",
    return: "返品可能条件はこちら",
    wrapping: "可能",
    shipDate: "1-3営業日",
    images: ["/gossi/1009.jpg", "/gossi/1009a.jpg", "/gossi/1009b.jpg"],
  },
  {
    id: 10,
    name: "【24AW】【レディース】 1PIU1UGUALE3 HOUNDSTOOTH MOCK / 113総柄ロゴ ハウンドトゥース ロングスリーブ モックネック Tシャツ (ブラック)",
    main: "1PIU1UGUALE3 GOLF",
    description: [
      "モックネックは首元を暖かく保つだけでなく、エレガントな印象を与えます",
      "1PIU1UGUALE3 GOLFよりMOCK NECK HOUNDSTOOTHの発表です。",
      "クラシックなモックネックデザインを特徴としています。",
      "モックネックは首元を暖かく保つだけでなく、エレガントな印象を与えます。",
      "軽量で通気性が良く、長時間の着用でも快適さを保ちます。",
      "ストレッチ性にも優れており、動きやすさも抜群で快適なプレーをサポートします。",
      "日本で10台しかない両面編みのコンピュータージャガード機を使用しており、非常に希少で貴重な素材に昇華プリントでアクセントをつけた唯一無二の素材です。",
      "カラーバリエーションは、WHITE、BEIGE、KHAKI、BLACKの4色からお好みのカラーをお選びいただけます。",
      "1PIU1UGUALE3が提案するGOLF LINE",
      "日本から世界に向けて発信するブランドとして世界中の上質な素材を贅沢に使用しラグジュアリーな商品をリリースし続ける1PIU1UGUALE3。",
      "ハイエンドラグジュアリーブランドが提案する高いデザイン性とスポーツの機能美を併せ持ち上質を知る全てのプレイヤーの為のウェアとしてリリース致します。革新的なハイテク素材を採用し、ただ派手な物ではなくテーラーリングを得意とする同ブランドならではの立体パターンにより洗練された高いデザイン性と最高のフィッティングを兼ね備え着る者全てに高揚感と優越感をもたらします。",
      "品切れを起こしている商品に関しましては、メーカー取り寄せによる販売が可能な場合もございます。",
      "お取り寄せをご希望の場合は、お気軽にお問い合わせ下さい。",
      "サイズ選びに不安のある方は、身長や体重等をお伺いしてアドバイスさせて頂きますので、お気軽にお問い合わせ下さい。",
      "万が一、ご購入頂きました商品のサイズが合わない場合はサイズ交換、またはご返品が可能です。",
      "お気軽にご利用下さいませ。",
      "※サイズ交換、またはご返品がお受け出来ない商品もありますので、事前にご相談下さい。",
    ],
    price: "45100",
    cent: "410",
    sizeImg: "/gossi/size010.png",
    size: [
      "1（在庫なし/再販売通知リクエスト可）",
      "2（残り1点）",
      "3（在庫なし/再販売通知リクエスト可）",
    ],
    color: "ブラック",
    material: "ポリエステル100%",
    materialOuter: "",
    materialSeparate: "",
    materialSynthetic: "",
    materialBody: "",
    materialLining: "",
    materialRibs: "",
    brand: "1PIU1UGUALE3 GOLF",
    itemNo: "GST205-POE518-BK99",
    catagory: "レディースウェア > Tシャツ・カットソー",
    gender: "女性",
    information: "",
    return: "返品可能条件はこちら",
    wrapping: "可能",
    shipDate: "1-3営業日",
    images: ["/gossi/10010.jpg", "/gossi/10010a.jpg"],
  },
  {
    id: 11,
    name: "CR-8 #02 / スタンドタイプ キャディバッグ (ブラック / アイボリー)",
    main: "BRIEFING",
    description: [
      "アメリカのカントリークラブでのラウンドをイメージして制作された「CR-8」をスタンドタイプにリモデル",
      "むかしながらのアウトドアウェアに見られるカラーを落とし込み、フォレストグリーンのロゴ刺繍とモスグリーンのテープ配置した「森」をイメージした配色に仕立てました。",
      "「VORTEX（R） CANVAS 」のナチュラルな風合いが、唯一無二の雰囲気を醸し出す、どこかクラシカルなルックスが魅力のキャディバッグです。",
      "-強く型崩れしにくい素材-",
      "・「最も毛羽の少ない紡績糸」といわれ、毛羽や毛玉を抑える効果を持つ、「VORTEX（R）紡績」という最新の紡績技術を用いた糸に、CORDURAを組み合わせる事で、より丈夫で型崩れしにくい「VORTEX（R） CANVAS」をメイン素材に使用。",
      "-コンパクトで充実した収納美-",
      "・フロントには大小3つのポケットを配置、大サイズのポケットにはメッシュの仕切りポケットを搭載し、使用頻度の高い小物アイテムを分けて収納することが可能。",
      "・右サイドには上下にジップポケット、ドリンクボトル等の収納に便利な保冷機能付きのスリットポケットを配置。",
      "上部のポケットにはアウターやキャディバッグのフードなど、かさばるアイテムなども収納することが可能です。",
      "・左サイドも同様に、ジップポケットを1か所、保冷機能付きのポケットを配置。",
      "計11か所のポケットを配置し、コンパクトなモデルでありながらも収納力を確保している。",
      "【WOODLAND SERIES】",
      "「森」をテーマに、クラシックなアウトドアウェアから着想を得たカラーリングとナチュラルな素材の表情が、唯一無二の雰囲気を醸し出す新シリーズ。",
      "「CR-8」をスタンドタイプにリニューアルしたニューモデルをメインに、クラシックさは残しつつゴルフシーンでの使いやすさにもこだわったラインナップとなっています。",
      "品切れを起こしている商品に関しましては、メーカー取り寄せによる販売が可能な場合もございます。",
      "お取り寄せをご希望の場合は、お気軽にお問い合わせ下さい。",
      "サイズ選びに不安のある方は、身長や体重等をお伺いしてアドバイスさせて頂きますので、お気軽にお問い合わせ下さい。",
      "万が一、ご購入頂きました商品のサイズが合わない場合はサイズ交換、またはご返品が可能です。",
      "お気軽にご利用下さいませ。",
      "※サイズ交換、またはご返品がお受け出来ない商品もありますので、事前にご相談下さい。",
    ],
    price: "79200",
    cent: "720",
    sizeImg: "/gossi/size011.png",
    size: ["フリーサイズ（残り1点）"],
    color: "ブラック",
    material: "モダール/コーデュラ×ヴォルテックス(R)キャンバス",
    materialOuter: "",
    materialSeparate: "",
    materialSynthetic: "",
    materialBody: "",
    materialLining: "",
    materialRibs: "",
    brand: "BRIEFING",
    itemNo: "BRG241D26-BK010",
    catagory: "キャディバッグ&カバー > キャディバッグ",
    gender: "ユニセックス",
    information: "",
    return: "返品可能条件はこちら",
    wrapping: "可能",
    shipDate: "1-3営業日",
    images: ["/gossi/10011.jpg", "/gossi/10011a.jpg", "/gossi/10011b.jpg"],
  },
  {
    id: 12,
    name: "CR-7 #03 / スタンドタイプ キャディバッグ (ブラック)",
    main: "BRIEFING",
    description: [
      "アスリートゴルファーに向けたスポーツライン【CORDURA（R） × SPECTRA SERIES】が完全リニューアル",
      "肩の負担を軽減するショルダーやタフな口枠の仕様、格子柄の生地を用いたスポーティーなルックスなど、「よりストイックに、さらにスピーディにゴルフを楽しみたい」というユーザーに向け、ドイツ語で「純粋なレーシング」という意味を持つ、RENNSPORT（レンシュポルト）シリーズとして生まれ変わりました",
      "-耐久性と軽量性を持ち合わせたメイン素材-",
      "・200デニールのコーデュラナイロンに、ホワイトカラーのスぺクトラリップ糸を格子状に組み合わせたオリジナルのファブリックをメインに使用。",
      "防弾ベストや防弾板（コックピット）等にも用いられる、水よりも軽量で鋼鉄の15倍もの強度を持つ素材「スペクトラ」をコーデュラナイロンと組み合わせることでさらに強度を高めるだけでなく、撥水性と透湿防水性を持たせる加工を施しています。",
      "・野外でのタフな使用に耐えうる機能性の高さはもちろん、アウトドアバッグに見られるような格子状の素材の表面感によるスポーティーなルックスも魅力的。",
      "-アスリートゴルファーに最適な機能性-",
      "・取り外し可能な4点式のダブルショルダー。",
      "ジョイントパーツが可動式となることで、体の動きに応じてショルダーストラップが馴染み、背負って移動した際に肩への負担を軽減する構造に。",
      "・TOP、口枠はハードユーザーに対応。",
      "ウエッジ類により摩擦が激しい口枠の下部には部分的にコーデュラナイロンを使用することで、摩擦強度を高めている。",
      "また、口枠部分が著しく消耗してしまった際には、口枠部分のパーツ交換が可能な構造を採用している。",
      "・従来モデルに比べさらに軽量化しながらも、大小14か所のポケットを配し、収納力アップを実現。",
      "各アウトポケットにはBRIEFINGのバッグに見られるデザインを落とし込み、ブランドらしさが表現されています。",
      "・軽量化に配慮された5角形のスタンドパーツには、BRIEFINGオリジナルデザインのものを採用。",
      "【RENNSPORTシリーズ】",
      "従来のCORDURA（R）× SPECTRAシリーズを全モデルリニューアルし、アクティブなアスリートゴルファーに向けた新シリーズが登場。他シリーズと違い、あえてロゴ表現を最小限に留め、細部のデザインでブランドを表現。「よりストイックに、さらにスピーディーにゴルフを楽しみたい」ユーザーへ向けたアイテムをお届けいたします。",
      "品切れを起こしている商品に関しましては、メーカー取り寄せによる販売が可能な場合もございます。",
      "お取り寄せをご希望の場合は、お気軽にお問い合わせ下さい。",
      "サイズ選びに不安のある方は、身長や体重等をお伺いしてアドバイスさせて頂きますので、お気軽にお問い合わせ下さい。",
      "万が一、ご購入頂きました商品のサイズが合わない場合はサイズ交換、またはご返品が可能です。",
      "お気軽にご利用下さいませ。",
      "※サイズ交換、またはご返品がお受け出来ない商品もありますので、事前にご相談下さい。",
    ],
    price: "79200",
    cent: "720",
    sizeImg: "/gossi/size012.png",
    size: ["フリーサイズ（残り1点）"],
    color: "ブラック",
    material: "210デニールコーデュラ×200デニールスペクトラリップ",
    materialOuter: "",
    materialSeparate: "",
    materialSynthetic: "",
    materialBody: "",
    materialLining: "",
    materialRibs: "",
    brand: "BRIEFING",
    itemNo: "BRG243D01-BK010",
    catagory: "キャディバッグ&カバー > キャディバッグ",
    gender: "ユニセックス",
    information: "",
    return: "返品可能条件はこちら",
    wrapping: "可能",
    shipDate: "1-3営業日",
    images: ["/gossi/10012.jpg"],
  },
  {
    id: 13,
    name: "OLSON LUXE HYBRID COVER / カモフラージュパターン 高密度ナイロン ユーティリティカバー (ブラック/ホワイト)",
    main: "MARK&LONA",
    description: [
      "高密度ナイロンをベースにプリントを施したユーティリティヘッドカバー。",
      "シグネチャーであるカモフラージュパターンをコラージュで再構築したデザイン。",
      "フロントには大きく存在感のあるサークルスカルプレートを配置。",
      "綿入りのコンパクトな立体フォルムで、ヘッドをしっかりガード。",
      "ロゴ入りの番手ダイヤル付き。",
      "内側にはクラブヘッドを傷つけない柔らかな起毛素材を使用。",
      "120-150ccのヘッドサイズに対応。",
      "品切れを起こしている商品に関しましては、メーカー取り寄せによる販売が可能な場合もございます。",
      "お取り寄せをご希望の場合は、お気軽にお問い合わせ下さい。",
      "サイズ選びに不安のある方は、身長や体重等をお伺いしてアドバイスさせて頂きますので、お気軽にお問い合わせ下さい。",
      "万が一、ご購入頂きました商品のサイズが合わない場合はサイズ交換、またはご返品が可能です。",
      "お気軽にご利用下さいませ。",
      "※サイズ交換、またはご返品がお受け出来ない商品もありますので、事前にご相談下さい。",
    ],
    price: "79200",
    cent: "720",
    sizeImg: "/gossi/size012.png",
    size: ["フリーサイズ（在庫あり）"],
    color: "ブラック×ホワイト",
    material: "",
    materialOuter: "ポリエステル100%",
    materialSeparate: "ポリエステル80% レーヨン20%",
    materialSynthetic: "合成皮革",
    materialBody: "",
    materialLining: "ポリエステル100%",
    materialRibs: "",
    brand: "MARK&LONA",
    itemNo: "MLS-4C-SH23-BKWH",
    catagory: "キャディバッグ&カバー > カバー類",
    gender: "ユニセックス",
    information: "",
    return: "返品可能条件はこちら",
    wrapping: "可能",
    shipDate: "1-3営業日",
    images: [
      "/gossi/10013.jpg",
      "/gossi/10013a.jpg",
      "/gossi/10013b.jpg",
      "/gossi/10013c.jpg",
      "/gossi/10013d.jpg",
      "/gossi/10013e.jpg",
    ],
  },
  {
    id: 14,
    name: "OLSON LUXE HYBRID COVER / クロコダイル調モノグラムパターン 高密度ナイロン ユーティリティカバー (ブラック/ブラック)",
    main: "MARK&LONA",
    description: [
      "高密度ナイロンをベースにプリントを施したユーティリティヘッドカバー。",
      "クロコダイル柄をベースにブランドロゴやスカルをキルティングステッチで表現したスーパーリアルモノグラムパターンのデザイン。",
      "フロントには大きく存在感のあるサークルスカルプレートを配置。",
      "綿入りのコンパクトな立体フォルムで、ヘッドをしっかりガード。",
      "ロゴ入りの番手ダイヤル付き。",
      "内側にはクラブヘッドを傷つけない柔らかな起毛素材を使用。",
      "120-150ccのヘッドサイズに対応。",
      "品切れを起こしている商品に関しましては、メーカー取り寄せによる販売が可能な場合もございます。",
      "お取り寄せをご希望の場合は、お気軽にお問い合わせ下さい。",
      "サイズ選びに不安のある方は、身長や体重等をお伺いしてアドバイスさせて頂きますので、お気軽にお問い合わせ下さい。",
      "万が一、ご購入頂きました商品のサイズが合わない場合はサイズ交換、またはご返品が可能です。",
      "お気軽にご利用下さいませ。",
      "※サイズ交換、またはご返品がお受け出来ない商品もありますので、事前にご相談下さい。",
    ],
    price: "13200",
    cent: "120",
    sizeImg: "/gossi/size014.png",
    size: ["フリーサイズ（在庫あり）"],
    color: "ブラック×ブラック",
    material: "",
    materialOuter: "ポリエステル100%",
    materialSeparate: "ポリエステル80% レーヨン20%",
    materialSynthetic: "合成皮革",
    materialBody: "",
    materialLining: "ポリエステル100%",
    materialRibs: "",
    brand: "MARK&LONA",
    itemNo: "MLS-4C-SH23-BKBK",
    catagory: "キャディバッグ&カバー > カバー類",
    gender: "ユニセックス",
    information: "",
    return: "返品可能条件はこちら",
    wrapping: "可能",
    shipDate: "1-3営業日",
    images: ["/gossi/10014.jpg"],
  },
  {
    id: 15,
    name: "【銀行振込・代金引換不可】【返品・交換不可】 OLSON LUXE CADDY BAG / カモフラージュパターン 高密度ナイロン スタンドタイプ キャディバック (ブラック/ホワイト)",
    main: "MARK&LONA",
    description: [
      "高密度ナイロンをベースにプリントを施したラグジュアリーなキャディバッグ",
      "軽量タイプのスタンド型キャディバッグ。",
      "シグネチャーであるカモフラージュパターンをコラージュで再構築したデザイン。",
      "高密度ナイロン素材をベースにプリントを施し、マットなスムース合皮とラメのような輝きの合皮を合わせ、アクセントにダイヤ型エンボスの鏡面合皮を使用。",
      "小物用ポケットはマグネット式でワンタッチで開閉でき、他にも細かな内ポケットを随所に配置。",
      "背負うことのできる2点式ショルダータイプ。",
      "随所に散りばめられたクリアブラックのストーンスタッズが煌びやかなアクセント。",
      "47インチクラブ対応のフルセットタイプ9型仕様、口枠は4分割仕切り。",
      "品切れを起こしている商品に関しましては、メーカー取り寄せによる販売が可能な場合もございます。",
      "お取り寄せをご希望の場合は、お気軽にお問い合わせ下さい。",
      "サイズ選びに不安のある方は、身長や体重等をお伺いしてアドバイスさせて頂きますので、お気軽にお問い合わせ下さい。",
      "万が一、ご購入頂きました商品のサイズが合わない場合はサイズ交換、またはご返品が可能です。",
      "お気軽にご利用下さいませ。",
      "※サイズ交換、またはご返品がお受け出来ない商品もありますので、事前にご相談下さい。",
    ],
    price: "15400",
    cent: "1400",
    sizeImg: "/gossi/size015.png",
    size: ["フリーサイズ（在庫あり）"],
    color: "ブラック×ホワイト",
    material: "ナイロン100%",
    materialOuter: "",
    materialSeparate: "ポリエステル80% レーヨン20%",
    materialSynthetic: "合成皮革",
    materialBody: "ポリエステル100%",
    materialLining: "",
    materialRibs: "",
    brand: "MARK&LONA",
    itemNo: "MLS-4C-SC21-BKWH",
    catagory: "キャディバッグ&カバー > キャディバッグ",
    gender: "ユニセックス",
    information: "",
    return: "返品可能条件はこちら",
    wrapping: "可能",
    shipDate: "1-3営業日",
    images: ["/gossi/10015.jpg"],
  },
  {
    id: 16,
    name: "MENS WR CORDURA CAP / サークルロゴ コーデュラストレッチ キャップ (ネイビー)",
    main: "BRIEFING",
    description: [
      "タフな使用にも対応できる、強度に優れたコーデュラストレッチ素材を採用したキャップ",
      "ラウンドはもちろん、アウトドアシーンにも活躍するアイテムです。",
      "-快適さを追求-",
      "・優れた耐摩耗性と引き裂き強度を備えたコーデュラ4wayストレッチをメインに使用。",
      "タフな使用に耐えうるのはもちろん、縦横自由自在に伸縮するストレッチ性を併せ持ち、程よくフィットした抜群のかぶり心地。",
      "・バックに搭載したBRIEFINGロゴ入りアジャスターは、フィット感を調整できるベルクロ仕様。",
      "-さりげないデザインポイント-",
      "・フロントにはBマークロゴのワッペンを、サイドには星条旗モチーフ、バックアジャスターにはBRIEFINGロゴ刺繍を配置し、どの角度からみても目を引くルックスに。",
      "【URABAN ATHLETE コレクション】",
      "BRIEFING GOLF のコンセプトである'URBAN GOLF'を体現し、ブランドらしいトレンドを落とし込んだスタイリッシュなゴルフコーディネートを提案する、BRIEFING GOLFのの中心となるアパレルコレクション。",
      "2024春夏シーズンでは、撥水性素材を使用した軽量アウターの他、オリジナル・モノグラムプリントを備えたアパレルを新たにローンチ。ポロシャツの一部アイテムにはリラックスフィットデザインを採用するなど、バリエーション豊富なアーバンスタイルゴルフウェアをお届けします。",
      "品切れを起こしている商品に関しましては、メーカー取り寄せによる販売が可能な場合もございます。",
      "お取り寄せをご希望の場合は、お気軽にお問い合わせ下さい。",
      "サイズ選びに不安のある方は、身長や体重等をお伺いしてアドバイスさせて頂きますので、お気軽にお問い合わせ下さい。",
      "万が一、ご購入頂きました商品のサイズが合わない場合はサイズ交換、またはご返品が可能です。",
      "お気軽にご利用下さいませ。",
      "※サイズ交換、またはご返品がお受け出来ない商品もありますので、事前にご相談下さい。",
    ],
    price: "8140",
    cent: "74",
    sizeImg: "/gossi/size016.png",
    size: ["フリーサイズ（残り1点）"],
    color: "ネイビー",
    material: "ナイロン91％ ポリウレタン9％",
    materialOuter: "",
    materialSeparate: "",
    materialSynthetic: "",
    materialBody: "",
    materialLining: "",
    materialRibs: "",
    brand: "BRIEFING",
    itemNo: "BRG241MA2-NV076",
    catagory: "グッズ > キャップ・サンバイザー",
    gender: "男性",
    information: "",
    return: "返品可能条件はこちら",
    wrapping: "可能",
    shipDate: "1-3営業日",
    images: [
      "/gossi/10016.jpg",
      "/gossi/10016a.jpg",
      "/gossi/10016b.jpg",
      "/gossi/10016c.jpg",
      "/gossi/10016d.jpg",
      "/gossi/10016e.jpg",
      "/gossi/10016f.jpg",
      "/gossi/10016g.jpg",
      "/gossi/10016h.jpg",
      "/gossi/10016i.jpg",
    ],
  },
  {
    id: 17,
    name: "【レディース】 EVER ULTRA SUEDE GLOVE [LEFT] / バイカラー スエード グローブ (アイボリー)",
    main: "MARK&LONA",
    description: [
      "鮮やかなバイカラーが特徴のスエードグローブ",
      "甲側には東レの人工皮革【ウルトラスエード®】を、内側には高密着のグリップ力が強い帝人の【コードレ®】素材を使用し、手とグリップに吸い付くようなフィット感が特徴です。",
      "品切れを起こしている商品に関しましては、メーカー取り寄せによる販売が可能な場合もございます。",
      "お取り寄せをご希望の場合は、お気軽にお問い合わせ下さい。",
      "サイズ選びに不安のある方は、身長や体重等をお伺いしてアドバイスさせて頂きますので、お気軽にお問い合わせ下さい。",
      "万が一、ご購入頂きました商品のサイズが合わない場合はサイズ交換、またはご返品が可能です。",
      "お気軽にご利用下さいませ。",
      "※サイズ交換、またはご返品がお受け出来ない商品もありますので、事前にご相談下さい。",
    ],
    price: "7150",
    cent: "65",
    sizeImg: "/gossi/size017.png",
    size: ["19cm（在庫あり）", "20cm（在庫あり）"],
    color: "アイボリー",
    material: "人工皮革",
    materialOuter: "",
    materialSeparate: "",
    materialSynthetic: "",
    materialBody: "",
    materialLining: "ポリウレタン65% ナイロン35%",
    materialRibs: "",
    brand: "MARK&LONA",
    itemNo: "MLS-4C-SG01-IVORY",
    catagory: "グッズ > グローブ",
    gender: "女性",
    information: "",
    return: "返品可能条件はこちら",
    wrapping: "可能",
    shipDate: "1-3営業日",
    images: ["/gossi/10017.jpg"],
  },
  {
    id: 18,
    name: "POM POM BEANIE FLEX / 113Gロゴ刺繍 ニットビーニー (グレー)",
    main: "1PIU1UGUALE3 GOLF",
    description: [
      "シンプルなコーディネートのワンポイントアクセントに",
      "1PIU1UGUALE3 GOLFよりPOM POM BEANIE FLEX FITの発表です。",
      "ポンポン付きのニットキャップ。",
      "ポンポンが付いていることで、シンプルなコーディネートのワンポイントアクセントに。",
      "また、小顔効果も期待できます。",
      "ユニセックスなので男女問わずご使用いただけます。",
      "カラーバリエーションは、GRAY、BLACKの2色からお好みのカラーをお選びいただけます。",
      "1PIU1UGUALE3が提案するGOLF LINE",
      "日本から世界に向けて発信するブランドとして世界中の上質な素材を贅沢に使用しラグジュアリーな商品をリリースし続ける1PIU1UGUALE3。",
      "ハイエンドラグジュアリーブランドが提案する高いデザイン性とスポーツの機能美を併せ持ち上質を知る全てのプレイヤーの為のウェアとしてリリース致します。革新的なハイテク素材を採用し、ただ派手な物ではなくテーラーリングを得意とする同ブランドならではの立体パターンにより洗練された高いデザイン性と最高のフィッティングを兼ね備え着る者全てに高揚感と優越感をもたらします。",
      "品切れを起こしている商品に関しましては、メーカー取り寄せによる販売が可能な場合もございます。",
      "お取り寄せをご希望の場合は、お気軽にお問い合わせ下さい。",
      "サイズ選びに不安のある方は、身長や体重等をお伺いしてアドバイスさせて頂きますので、お気軽にお問い合わせ下さい。",
      "万が一、ご購入頂きました商品のサイズが合わない場合はサイズ交換、またはご返品が可能です。",
      "お気軽にご利用下さいませ。",
      "※サイズ交換、またはご返品がお受け出来ない商品もありますので、事前にご相談下さい。",
    ],
    price: "13200",
    cent: "120",
    sizeImg: "/gossi/size018.png",
    size: ["フリーサイズ（残り1点）"],
    color: "グレー",
    material: "",
    materialOuter: "",
    materialSeparate: "",
    materialSynthetic: "",
    materialBody: "",
    materialLining: "",
    materialRibs: "",
    brand: "1PIU1UGUALE3 GOLF",
    itemNo: "GRG422-ACP011-GY95",
    catagory: "グッズ > キャップ・サンバイザー",
    gender: "ユニセックス",
    information: "",
    return: "返品可能条件はこちら",
    wrapping: "可能",
    shipDate: "1-3営業日",
    images: ["/gossi/10018.jpg", "/gossi/10018a.jpg", "/gossi/10018b.jpg"],
  },
  {
    id: 19,
    name: "POM POM BEANIE FLEX / 113Gロゴ刺繍 ニットビーニー (ブラック)",
    main: "1PIU1UGUALE3 GOLF",
    description: [
      "シンプルなコーディネートのワンポイントアクセントに",
      "1PIU1UGUALE3 GOLFよりPOM POM BEANIE FLEX FITの発表です。",
      "ポンポン付きのニットキャップ。",
      "ポンポンが付いていることで、シンプルなコーディネートのワンポイントアクセントに。",
      "また、小顔効果も期待できます。",
      "ユニセックスなので男女問わずご使用いただけます。",
      "カラーバリエーションは、GRAY、BLACKの2色からお好みのカラーをお選びいただけます。",
      "1PIU1UGUALE3が提案するGOLF LINE",
      "日本から世界に向けて発信するブランドとして世界中の上質な素材を贅沢に使用しラグジュアリーな商品をリリースし続ける1PIU1UGUALE3。",
      "ハイエンドラグジュアリーブランドが提案する高いデザイン性とスポーツの機能美を併せ持ち上質を知る全てのプレイヤーの為のウェアとしてリリース致します。革新的なハイテク素材を採用し、ただ派手な物ではなくテーラーリングを得意とする同ブランドならではの立体パターンにより洗練された高いデザイン性と最高のフィッティングを兼ね備え着る者全てに高揚感と優越感をもたらします。",
      "品切れを起こしている商品に関しましては、メーカー取り寄せによる販売が可能な場合もございます。",
      "お取り寄せをご希望の場合は、お気軽にお問い合わせ下さい。",
      "サイズ選びに不安のある方は、身長や体重等をお伺いしてアドバイスさせて頂きますので、お気軽にお問い合わせ下さい。",
      "万が一、ご購入頂きました商品のサイズが合わない場合はサイズ交換、またはご返品が可能です。",
      "お気軽にご利用下さいませ。",
      "※サイズ交換、またはご返品がお受け出来ない商品もありますので、事前にご相談下さい。",
    ],
    price: "13200",
    cent: "120",
    sizeImg: "/gossi/size019.png",
    size: ["フリーサイズ（残り1点）"],
    color: "ブラック",
    material: "",
    materialOuter: "",
    materialSeparate: "",
    materialSynthetic: "",
    materialBody: "",
    materialLining: "",
    materialRibs: "",
    brand: "1PIU1UGUALE3 GOLF",
    itemNo: "GRG422-ACP011-BK99",
    catagory: "グッズ > キャップ・サンバイザー",
    gender: "ユニセックス",
    information: "",
    return: "返品可能条件はこちら",
    wrapping: "可能",
    shipDate: "1-3営業日",
    images: ["/gossi/10019.jpg", "/gossi/10019a.jpg", "/gossi/10019b.jpg"],
  },
  {
    id: 20,
    name: "TECNICAL CAP-2 / ベースボールキャップ (ライトグレー)",
    main: "TFW49",
    description: [
      "2024AW COLLECTION",
      "品切れを起こしている商品に関しましては、メーカー取り寄せによる販売が可能な場合もございます。",
      "お取り寄せをご希望の場合は、お気軽にお問い合わせ下さい。",
      "サイズ選びに不安のある方は、身長や体重等をお伺いしてアドバイスさせて頂きますので、お気軽にお問い合わせ下さい。",
      "万が一、ご購入頂きました商品のサイズが合わない場合はサイズ交換、またはご返品が可能です。",
      "お気軽にご利用下さいませ。",
      "※サイズ交換、またはご返品がお受け出来ない商品もありますので、事前にご相談下さい。",
    ],
    price: "11000",
    cent: "110",
    sizeImg: "/gossi/size020.png",
    size: ["フリーサイズ（在庫あり）"],
    color: "ライトグレー",
    material: "",
    materialOuter: "",
    materialSeparate: "",
    materialSynthetic: "",
    materialBody: "",
    materialLining: "",
    materialRibs: "",
    brand: "TFW49",
    itemNo: "T132420009-LGY",
    catagory: "グッズ > キャップ・サンバイザー",
    gender: "ユニセックス",
    information: "",
    return: "返品可能条件はこちら",
    wrapping: "可能",
    shipDate: "1-3営業日",
    images: [
      "/gossi/10020.jpg",
      "/gossi/10020a.jpg",
      "/gossi/10020b.jpg",
      "/gossi/10020c.jpg",
      "/gossi/10020d.jpg",
      "/gossi/10020e.jpg",
      "/gossi/10020f.jpg",
      "/gossi/10020g.jpg",
    ],
  },
];

export default productData;
