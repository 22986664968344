import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <div className="haul_img">
          <dl className="footer__sitemap dl-expand">
            <dt>
              <Link
                className="unstyled collapsed"
                to="/"
                data-toggle="collapse"
                style={{ textDecoration: "none" }}
              >
                営業のご案内
              </Link>
            </dt>
            <dd className="collapse" id="footer_calendar">
              <div
                className="footer__sitemap__calendar"
                style={{ marginLeft: "-5%" }}
              >
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th
                        style={{
                          textAlign: "center",
                          padding: "10px 0px 10px 0",
                        }}
                      >
                        曜日
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          padding: "10px 0px 10px 0",
                        }}
                      >
                        通販対応時間
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          padding: "10px 0px 10px 0",
                        }}
                      >
                        当日発送締切時間
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-body-color">
                      <td>日</td>
                      <td>定休日</td>
                      <td>---</td>
                    </tr>
                    <tr className="text-body-color">
                      <td>月</td>
                      <td>10:00〜19:00</td>
                      <td>12:00</td>
                    </tr>
                    <tr className="text-body-color">
                      <td>火</td>
                      <td>10:00〜19:00</td>
                      <td>12:00</td>
                    </tr>
                    <tr className="text-body-color">
                      <td>水</td>
                      <td>10:00〜19:00</td>
                      <td>12:00</td>
                    </tr>
                    <tr className="text-body-color">
                      <td>木</td>
                      <td>10:00〜19:00</td>
                      <td>12:00</td>
                    </tr>
                    <tr className="text-body-color">
                      <td>金</td>
                      <td>10:00〜19:00</td>
                      <td>12:00</td>
                    </tr>
                    <tr className="text-body-color">
                      <td>土</td>
                      <td>10:00〜19:00</td>
                      <td>12:00</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </dd>
          </dl>
        </div>
        <p className="upp_txt">follow GOSSIP GOLF</p>
        <p className="haul_txt">最新情報をSNSで！</p>
        <div className="ul__div">
          <img
            width="35"
            height="35"
            src="https://img.icons8.com/ios/50/1A1A1A/twitterx--v2.png"
            alt="twitterx--v2"
          />

          <img
            width="35"
            height="35"
            src="https://img.icons8.com/material-outlined/50/1A1A1A/instagram-new--v1.png"
            alt="instagram-new--v1"
          />

          <img
            width="35"
            height="35"
            src="https://img.icons8.com/ios-glyphs/50/1A1A1A/facebook-new.png"
            alt="facebook-new"
          />
        </div>
        <p className="upp_txt">Staff</p>
        <div className="img_ul_div">
          <div className="img__inner">
            <img
              alt=""
              src="https://image.salesnauts.com/gossipgolf/img/user/2019/08/ST7sHUH1.jpg?width=80"
            />
            <img
              alt=""
              src="https://image.salesnauts.com/gossipgolf/img/ppfjcwND1NwM.jpg?width=80"
            />
            <img
              alt=""
              src="https://image.salesnauts.com/gossipgolf/img/user/2019/08/LFqR5Wnx.jpg?width=80"
            />
            <img
              alt=""
              src="https://image.salesnauts.com/gossipgolf/img/user/2019/09/oz31ZoLL.jpg?width=80"
            />
            <img
              alt=""
              src="https://image.salesnauts.com/gossipgolf/img/user/2019/09/Pjehiu71.jpg?width=80"
            />
            <img
              alt=""
              src="https://image.salesnauts.com/gossipgolf/img/6jaqQneUpj1G.png?width=80"
            />
            <img
              alt=""
              src="https://image.salesnauts.com/gossipgolf/img/user/2019/09/nLwU5TkC.jpg?width=80"
            />
            <img
              alt=""
              src="https://image.salesnauts.com/gossipgolf/img/2oN9BqWUPVCV.jpg?width=80"
            />
            <img
              alt=""
              src="https://image.salesnauts.com/gossipgolf/img/6iGrTB1x1ANB.jpg?width=80"
            />
          </div>
        </div>
        <div className="img2_div">
          <img
            alt=""
            src="https://image.salesnauts.com/gossipgolf/img/32QLKPqRUgQC.jpg?width=80"
          />
          <img
            alt=""
            src="https://image.salesnauts.com/gossipgolf/img/sotRVnE9tiLE.jpg?width=80"
          />
          <img
            alt=""
            src="https://image.salesnauts.com/gossipgolf/img/iMqbY6AhWxoj.jpg?width=80"
          />
          <img
            alt=""
            src="https://image.salesnauts.com/gossipgolf/img/c9ZkKdneGhez.jpg?width=80"
          />
          <img
            alt=""
            src="https://image.salesnauts.com/gossipgolf/img/zBwYzH6TG94S.jpg?width=80"
          />
        </div>
        <p className="footer__shop-info__tel">
          お電話からのご注文／お問い合わせ :
          <Link to="tel:0761-23-5999">(415) 425-9345</Link>
        </p>
        <p className="footer__shop-info__about">
          <font _mstmutation="1">
            <Link to="/" _mstmutation="1" onClick={toTop}>
              ホーム
            </Link>
            ｜
            <Link to="/contact" _mstmutation="1" onClick={toTop}>
              お問い合わせ
            </Link>
            ｜
            <Link to="/privacy" _mstmutation="1" onClick={toTop}>
              個人情報保護方針
            </Link>
            ｜
            <Link to="/particular" _mstmutation="1" onClick={toTop}>
              特定商取引法に基づく表示
            </Link>
          </font>
        </p>
        <div className="footTitleImg">
          <img alt="" src="/head-foot/head.png" />
        </div>
        <div className="footer__shop-info__copyright">
          <small>© GOSSIP GOLF All rights reserved.</small>
        </div>
        <hr className="footer__divider"></hr>
        <div className="footer__payments">
          <Link to="/">
            <img
              src="https://www.gossipgolf.com/assets/images/plugins/amazonpay-positive.png"
              alt="Amazon Pay"
              width="90"
              height="17"
              _mstalt="132262"
            />
          </Link>
          <Link to="/">
            <img
              src="https://checkout.rakuten.co.jp/rpay/logo/s_sb_20059.jpg"
              alt="楽天ペイ"
              width="100"
              height="30"
              _mstalt="7913217"
            />
          </Link>
          <Link to="/">
            <img
              src="https://www.gossipgolf.com/assets/images/plugins/paidy.png"
              alt="あと払いペイディ"
              width="80"
              height="32"
              _mstalt="15071758"
            />
          </Link>
        </div>
        <p className="footer__shop-info__ssl">
          本サイトではSSLによる暗号化通信を行っておりますので、お客様に安心してお買い物を楽しんでいただけます。
        </p>
        <div className="footer__pitch">
          <small>
            ファッションコマースプラットフォーム
            <Link to="/" target="_blank" rel="noopener">
              Salesnauts
            </Link>
          </small>
        </div>
      </div>
    </>
  );
}

export default Footer;
