import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contact.css";
import { Link } from "react-router-dom";

function Privacy() {
  return (
    <div className="main-container">
      <Header />
      <div className="contactAll">
        <div className="contactLeftBottomTitle">
          <h1 className="privacy_title_h1">サイトに関して</h1>
          <h2 className="privacy_title_h2">個人情報保護方針</h2>
          <hr />
          <h3>個人情報保護方針</h3>
          <p>
            本サイトを運営しておりますGOSSIP
            GOLFはお客様のプライバシーを尊重し、個人情報を保護するために細心の注意を払います。
          </p>
          <p>
            個人情報を収集する際には、提供者に対し収集する目的を明確にし、その目的達成に必要な限度において
            適法かつ公正な手段を用います。またその個人情報はカタログやDMの送付、商品発送や連絡等の通信販売、お問い合わせへのご返答、その他公表された範囲内においてのみ利用いたします。
          </p>
          <p>
            下記の場合を除き、ご本人の同意を得ることなく個人情報を第三者に開示または提供しません。開示または提供する場合は、個人情報の不適切な流出防止をはじめとする保護のための措置が、相手方で確保されるよう努めます。
          </p>
          <ul>
            <li>業務委託先が業務上必要な範囲内でのみ利用する場合</li>
            <li>法令等に基づいた正当な開示の必要性がある場合</li>
            <li>個人を識別できない統計データの形式で利用する場合</li>
          </ul>
          <p>
            お客様の個人情報および個人情報が蓄積された機器を不正なアクセス・漏洩等から保護するため必要な内部規則および管理体制を整備し、適切な方法で管理いたします。
          </p>
          <p>
            当社が保有する個人情報について、ご本人から開示・訂正・削除等のご要請があった場合は、ご請求がご本人によるものであることが確認できた後に、合理的な範囲で対応いたします。
          </p>
          <p>
            お客様の個人情報についてのご質問は下記の窓口へお願いいたします。
          </p>
          <hr />
          <h3>プライバシーポリシー（非個人情報の取扱いについて）</h3>

          <p>
            当ホームページでは、お客様の利便性の向上やコンテンツの充実、利用状況の統計的把握等を目的として、クッキーを使用しております。クッキーとは、ウェブサイトがお客様のコンピュータに送信するデータファイルで、お客様が同じウェブサイトを再度訪れた際に、そのウェブサイトがお客様のコンピュータからのアクセスであることを認識できるようにするためのものです。お客様の承諾を得た場合を除き、当社がクッキーの使用によって個人を特定できる情報を取得することはありません。
          </p>
          <p>
            当ホームページでは、クッキーを活用して、下記のアドテクノロジーを以下の目的で利用しております。なお、これらの利用にあたって、当社がお客様の個人情報を各提供者に提供することはありません。
          </p>
          <h4>Googleアナリティクス / Google広告</h4>
          <ul>
            <li>提供者：Google</li>
            <li>
              利用目的：
              <ul>
                <li>
                  本サイトの利用者数やトラフィックを調査し、サービスの改善を行うため
                </li>
                <li>
                  本サイトを訪問したお客様に対して適切な広告を配信するため
                </li>
              </ul>
            </li>
            <li>
              アクセス情報の収集方法および利用方法：
              <br />
              <Link
                to="https://www.google.com/analytics/terms/jp.html"
                style={{ color: "#268bed" }}
              >
                Googleアナリティクス利用規約
              </Link>
              および
              <Link
                to="https://www.google.co.jp/intl/ja/policies/privacy/"
                style={{ color: "#268bed" }}
              >
                Google プライバシーポリシー
              </Link>
              に規定
            </li>
            <li>
              アクセス情報の収集および広告の配信を無効にする方法：
              <br />
              <Link
                to="https://tools.google.com/dlpage/gaoptout"
                style={{ color: "#268bed" }}
              >
                Google アナリティクス オプトアウト アドオン
              </Link>
              、
              <Link
                to="https://adssettings.google.com/"
                style={{ color: "#268bed" }}
              >
                Google広告設定
              </Link>
              をご覧ください
            </li>
          </ul>
          <h4>Yahoo!広告 ディスプレイ広告（運用型）</h4>
          <ul>
            <li>提供者：LINEヤフー株式会社</li>
            <li>
              利用目的：
              <br />
              <ul>
                <li>
                  本サイトを訪問したお客様に対して適切な広告を配信するため
                </li>
              </ul>
            </li>
            <li>
              アクセス情報の収集方法および利用方法：
              <br />
              <Link
                to="https://btoptout.yahoo.co.jp/optout/faq.html"
                style={{ color: "#268bed" }}
              >
                Yahoo!マーケティングソリューションにおけるパーソナルデータの利用
              </Link>
            </li>
            <li>
              広告の配信を無効にする方法：
              <br />
              <Link
                to="https://accounts.yahoo.co.jp/privacy/optout/ads"
                style={{ color: "#268bed" }}
              >
                行動ターゲティング広告の設定
              </Link>
            </li>
          </ul>
          <h4>Microsoft Clarity</h4>
          <ul>
            <li>提供者：Microsoft</li>
            <li>
              利用目的：
              <br />
              <ul>
                <li>
                  Microsoft
                  Clarityは、お客様が訪問したページ、サイトの滞在時間、訪問経路および何をクリックしたか等に関する情報を保存します。この情報がお客様の特定に使われることはありません。当サイトはそれらの情報をサイト利用状況の分析と改善の目的に限り使用します。
                </li>
              </ul>
            </li>
            <li>
              アクセス情報の収集方法および利用方法：
              <br />
              <Link
                to="https://clarity.microsoft.com/terms"
                style={{ color: "#268bed" }}
              >
                Microsoft Clarity利用規約
              </Link>
              および
              <Link
                to="https://privacy.microsoft.com/ja-jp/privacystatement"
                style={{ color: "#268bed" }}
              >
                Microsoftプライバシーステートメント
              </Link>
              に規定
            </li>
          </ul>
          <p>
            お客様は、お使いのブラウザの設定により、クッキーを受け取ったときに通知を表示したり、受け取りを拒否することができますが、拒否された場合、一部のコンテンツを利用できない場合がありますので、あらかじめご了承ください。設定方法はブラウザによって異なりますので、お使いのブラウザのヘルプなどをご覧ください。
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Privacy;
