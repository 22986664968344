import "../css/header.css";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

function Header() {
  const { cartItems } = useContext(CartContext);
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="header-container">
      <div className="allDiv">
        <div className="headerDiv">
          <div className="headerMid">
            <nav className="navigation">
              <ul className=" main-nav">
                <li className="menu-item">
                  <Link to="/" className="homeToA" onClick={toTop}>
                    ホーム
                  </Link>
                </li>
                <li className="menu-item">
                  <Link to="/privacy" className="homeToA" onClick={toTop}>
                    個人情報保護方針
                  </Link>
                </li>
                <li className="menu-item">
                  <Link to="/contact" onClick={toTop}>
                    お問い合わせ
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="leftImg">
            <img alt="" src="/head-foot/head.png" />
          </div>
          <div className="rightT">
            <img
              width="24"
              height="24"
              src="https://img.icons8.com/material-outlined/50/clock--v1.png"
              alt="clock--v1"
            />
            <img
              width="24"
              height="24"
              src="https://img.icons8.com/material-outlined/50/like.png"
              alt="like"
            />
            <p className="cartItemCount">{cartItems.length}</p>
            <Link to="/cartpage" className="to-add" onClick={toTop}>
              <img
                width="24"
                height="24"
                src="https://img.icons8.com/material-rounded/50/shopping-cart.png"
                alt="shopping-cart"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
