import "./App.css";
import Main from "./Component/Main";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Privacy from "./Component/Privacy";
import ProductDetail from "./Component/ProductDetail";
import CartPage from "./Component/CartPage";
import CheckOut from "./Component/CheckOut";
import { CartProvider } from "./Component/CartContext";
import PaySuccess from "./Component/SuccessPay";
import Particular from "./Component/Particular";
import Contact from "./Component/Contact";

function App() {
  return (
    <CartProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/shopDetails/:id" element={<ProductDetail />} />
          <Route path="/cartpage" element={<CartPage />} />
          <Route path="/checkout" element={<CheckOut />} />
          <Route path="/paysuccess" element={<PaySuccess />} />
          <Route path="/particular" element={<Particular />} />
        </Routes>
      </Router>
    </CartProvider>
  );
}

export default App;
