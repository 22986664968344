import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contact.css";

function Contact() {
  const [name, setName] = useState({ lastName: "", firstName: "" });
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const contactSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!name.lastName || !name.firstName) newErrors.name = "Enter a full name";
    if (!mail) newErrors.mail = "Enter an email";
    if (!phone) newErrors.phone = "Enter a phone number";
    if (!message) newErrors.message = "Enter a message";

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    } else {
      alert("送信が成功しました！");
      setName({ lastName: "", firstName: "" });
      setMail("");
      setPhone("");
      setMessage("");
    }
  };

  return (
    <div className="main-container">
      <Header />
      <div className="contactAll">
        <div className="contactLeftBottomTitle">
          <div className="term-h1">
            <h1>お問い合わせ</h1>
          </div>
          <form
            className="account-inquiry__form form validation"
            onSubmit={contactSubmit}
          >
            <fieldset>
              <legend className="required" aria-required="true">
                お名前
              </legend>
              <div className="layout-row">
                <div className="flex">
                  <div className="form-group">
                    <label htmlFor="last_name">お名前（姓）</label>
                    <input
                      type="text"
                      id="last_name"
                      value={name.lastName}
                      onChange={(e) =>
                        setName({ ...name, lastName: e.target.value })
                      }
                      className="form-control"
                      required
                      placeholder="例) 山田"
                    />
                  </div>
                </div>
                <div className="flex">
                  <div className="form-group">
                    <label htmlFor="first_name">お名前（名）</label>
                    <input
                      type="text"
                      id="first_name"
                      value={name.firstName}
                      onChange={(e) =>
                        setName({ ...name, firstName: e.target.value })
                      }
                      className="form-control"
                      required
                      placeholder="例) 太郎"
                    />
                  </div>
                </div>
              </div>
            </fieldset>

            <fieldset>
              <legend className="required" aria-required="true">
                メールアドレス
              </legend>
              <div>
                <div className="form-group">
                  <label htmlFor="email">メールアドレス</label>
                  <input
                    type="email"
                    id="email"
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                    className="form-control validate-mx"
                    required
                    placeholder="例) connect@cidile.life"
                  />
                  <p className="input-hint">
                    送信後に「お問い合わせメール」が配信されます。
                    <br />
                    アドレスに誤りがあるとお問い合わせの確認ができなくなるのでご注意ください。
                  </p>
                </div>
              </div>
            </fieldset>

            <fieldset>
              <legend>電話番号</legend>
              <div>
                <div className="form-group">
                  <label htmlFor="tel">電話番号</label>
                  <input
                    type="tel"
                    id="tel"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="form-control tel"
                    placeholder="例) 09001234567"
                    required
                  />
                  <p className="input-hint">
                    日中ご連絡が可能な携帯電話番号の入力を推奨
                  </p>
                </div>
              </div>
            </fieldset>

            <fieldset>
              <legend className="required" aria-required="true">
                お問い合わせ内容
              </legend>
              <div>
                <div className="form-group">
                  <label htmlFor="message">お問い合わせ内容</label>
                  <textarea
                    id="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="form-control"
                    rows="6"
                    placeholder="お問い合わせ内容を入力してください"
                    required
                  ></textarea>
                </div>
              </div>
            </fieldset>

            <div
              className="g-recaptcha"
              data-sitekey="YOUR_SITE_KEY"
              data-callback="recaptchaSuccess"
            ></div>
            <input
              type="submit"
              value="送信する"
              className="btn btn-primary btn-lg btn-block"
            />
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
