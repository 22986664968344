import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/productdetails.css";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const product = productData.find((item) => item.id === parseInt(id));
  const { addToCart } = useContext(CartContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeTab, setActiveTab] = useState("colours");
  const [selectedSize, setSelectedSize] = useState(null);

  const handleAddToCart = () => {
    if (!selectedSize) {
      alert("サイズを選択してください！");
      return;
    }
    addToCart(product.id, 1, selectedSize, product.cent);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/cartpage");
  };
  if (!product) {
    return <div>商品が見つかりません</div>;
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleClick = (size) => {
    setSelectedSize(size);
  };

  return (
    <div className="main-container">
      <Header />
      <div className="shopDiv">
        <div className="product-detail">
          <div className="product-images">
            <div className="carousel">
              {product.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${product.name}`}
                  style={{ display: index === currentIndex ? "block" : "none" }}
                />
              ))}
            </div>
            <div className="carousel-buttons">
              {product.images.map((image, index) => (
                <button key={index} onClick={() => setCurrentIndex(index)}>
                  <img src={image} alt={`${product.name}`} />
                </button>
              ))}
            </div>
          </div>
          <div className="product-info">
            <h3 className="sldo__name">
              <strong style={{ color: "#000" }}>{product.main}</strong>
            </h3>
            <h3 className="sldo__name">{product.name}</h3>
            <div className="prices sale">
              <p className="box__price">
                <span className="price">
                  ¥{product.price}
                  <span className="tax">(税込)</span>
                </span>
                {product.off && (
                  <span className="box__price--rate">({product.off} Off)</span>
                )}
              </p>
            </div>
            <h3 className="sldo__name">{product.cent} ポイント還元</h3>
            <h3 className="sldo__name">発送目安日：1-3営業日</h3>
            <div className="attention-wrapper">
              <p className="attention">
                この商品は 送料無料 でお届けします
                <br />
                （※一部例外となる地域がございます）
              </p>
            </div>

            <div className="rio-product-option-title">サイズ</div>
            <div className="sldo__size_div">
              <select
                value={selectedSize}
                onChange={(e) => handleClick(e.target.value)}
                className={selectedSize ? "selected" : ""}
              >
                <option value="">選択してください</option>
                {product.size.map((size, index) => (
                  <option key={index} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>
            <div className="btnDiv">
              <button className="add-to-cart" onClick={handleAddToCart}>
                カートに追加
              </button>
            </div>
            <div className="item-des-color">
              <div className="tab-set__tab-group">
                <div className="tab-set__tab-window">
                  <div
                    className={`tab-set__tab ${
                      activeTab === "description" ? "is-active" : ""
                    }`}
                    onClick={() => handleTabChange("description")}
                  >
                    サイズ・素材
                  </div>
                  <div
                    className={`tab-set__tab ${
                      activeTab === "colours" ? "is-active" : ""
                    }`}
                    onClick={() => handleTabChange("colours")}
                  >
                    アイテム説明
                  </div>
                </div>
              </div>
              <div className="tab-set__body-group">
                <div
                  className={`tab-set__body ${
                    activeTab === "description" ? "is-active" : ""
                  }`}
                >
                  <img alt="" src={product.sizeImg} />
                </div>
                <div
                  className={`colors-item ${
                    activeTab === "colours" ? "is-active" : ""
                  }`}
                >
                  <ul className="describe__ul">
                    {product.description.map((description, index) => (
                      <li key={index}>{description}</li>
                    ))}
                  </ul>
                  <div className="description-base_info_table_wrapper">
                    <table className="description-base_info_table">
                      <tr>
                        <td>カラー</td>
                        <td style={{ width: "3%" }}>：</td>
                        <td>
                          <font _mstmutation="1">{product.color}</font>
                        </td>
                      </tr>
                      {product.material && (
                        <tr>
                          <td>素材</td>
                          <td style={{ width: "3%" }}>：</td>
                          <td>
                            <font _mstmutation="1">{product.material}</font>
                          </td>
                        </tr>
                      )}
                      {product.materialBody && (
                        <tr>
                          <td>素材（本体）</td>
                          <td style={{ width: "3%" }}>：</td>
                          <td>
                            <font _mstmutation="1">{product.materialBody}</font>
                          </td>
                        </tr>
                      )}
                      {product.materialLining && (
                        <tr>
                          <td>素材(裏地)</td>
                          <td style={{ width: "3%" }}>：</td>
                          <td>
                            <font _mstmutation="1">
                              {product.materialLining}
                            </font>
                          </td>
                        </tr>
                      )}
                      {product.materialRibs && (
                        <tr>
                          <td>素材(リブ)</td>
                          <td style={{ width: "3%" }}>：</td>
                          <td>
                            <font _mstmutation="1">{product.materialRibs}</font>
                          </td>
                        </tr>
                      )}
                      {product.materialOuter && (
                        <tr>
                          <td>素材(表地)</td>
                          <td style={{ width: "3%" }}>：</td>
                          <td>
                            <font _mstmutation="1">
                              {product.materialOuter}
                            </font>
                          </td>
                        </tr>
                      )}
                      {product.materialSeparate && (
                        <tr>
                          <td>素材(別布)</td>
                          <td style={{ width: "3%" }}>：</td>
                          <td>
                            <font _mstmutation="1">
                              {product.materialSeparate}
                            </font>
                          </td>
                        </tr>
                      )}
                      {product.materialSynthetic && (
                        <tr>
                          <td>素材(合成皮革パーツ)</td>
                          <td style={{ width: "3%" }}>：</td>
                          <td>
                            <font _mstmutation="1">
                              {product.materialSynthetic}
                            </font>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td>ブランド</td>
                        <td style={{ width: "3%" }}>：</td>
                        <td>
                          <font _mstmutation="1">{product.brand}</font>
                        </td>
                      </tr>
                      <tr>
                        <td>品番</td>
                        <td style={{ width: "3%" }}>：</td>
                        <td>
                          <font _mstmutation="1">{product.itemNo}</font>
                        </td>
                      </tr>
                      <tr>
                        <td>カテゴリ</td>
                        <td style={{ width: "3%" }}>：</td>
                        <td>
                          <font _mstmutation="1">{product.catagory}</font>
                        </td>
                      </tr>
                      <tr>
                        <td>性別</td>
                        <td style={{ width: "3%" }}>：</td>
                        <td>{product.gender}</td>
                      </tr>
                      {product.information && (
                        <tr>
                          <td>着用モデル情報</td>
                          <td style={{ width: "3%" }}>：</td>
                          <td>{product.information}</td>
                        </tr>
                      )}

                      <tr>
                        <td>返品</td>
                        <td style={{ width: "3%" }}>：</td>
                        <td>{product.return}</td>
                      </tr>
                      <tr>
                        <td>ラッピング</td>
                        <td style={{ width: "3%" }}>：</td>
                        <td>{product.wrapping}</td>
                      </tr>
                      <tr>
                        <td>発送目安日</td>
                        <td style={{ width: "3%" }}>：</td>
                        <td>{product.shipDate}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetail;
