import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import productData from "../Datas/productData";
import { Link } from "react-router-dom";

function Main() {
  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="promo-banner-container">
          <div className="wrapper">
            <p>GOSSIP GOLF FACTORYのページはこちら</p>
          </div>
        </div>

        <div className="twoDiv">
          <img alt="" src="/main/1001.jpg" />
          <img alt="" src="/main/1002.jpg" />
          <img alt="" src="/main/1003.jpg" />
        </div>

        {/* BRAND FOCUS */}
        <div
          id="shopify-section-b47d9df2-d926-46fe-a1e2-44aa689740e5"
          className="shopify-section"
        >
          <div className="custom-field">
            <div className="w1120 mb50">
              <div className="page-builder__part page-builder__part--hr">
                <hr />
              </div>
              <div className="text-center">
                <img
                  alt=""
                  src="https://image.salesnauts.com/gossipgolf/img/33bVDrJZroea.jpg"
                />
              </div>
              <div className="text-center" style={{ marginBottom: "5%" }}>
                <img
                  width="1160"
                  height="435"
                  alt=""
                  src="https://image.salesnauts.com/gossipgolf/img/7yqm2AF9ry7z.jpg"
                />
              </div>
              <div className="page-builder__part page-builder__part--hr">
                <hr />
              </div>
              <div className="text-center">
                <img
                  alt=""
                  src="https://image.salesnauts.com/gossipgolf/img/N4xVo1xc5317.jpg"
                />
              </div>
              <div className="text-center" style={{ marginBottom: "5%" }}>
                <img
                  width="1160"
                  height="435"
                  alt=""
                  src="https://image.salesnauts.com/gossipgolf/img/xhaqNRziP4ms.jpg"
                />
              </div>
              <div className="page-builder__part page-builder__part--hr">
                <hr />
              </div>
              <div className="text-center">
                <img
                  alt=""
                  src="https://image.salesnauts.com/gossipgolf/img/jM265ip7BNqJ.jpg"
                />
              </div>
            </div>
          </div>
        </div>

        {/* 人気 */}
        <div
          id="shopify-section-1662364965370b96b8"
          className="shopify-section"
        >
          <div className="title">
            <h2>
              <span>MEN'S WEAR</span>
            </h2>
          </div>
        </div>

        <div className="mainBox">
          <div className="container-fluid">
            {/* top */}
            <div className="top-container">
              <div className="product-grid">
                {productData
                  .filter((e) => e.id >= 1 && e.id <= 5)
                  .map((brand) => (
                    <div className="product-category" key={brand.id}>
                      <div className="image-container">
                        <Link
                          to={`/shopDetails/${brand.id}`}
                          onClick={handleNavigate}
                        >
                          <img alt="" src={brand.images[0]} />
                        </Link>

                        <h2>{brand.main}</h2>
                        <div className="pp-pre">
                          <h4 className="red-price">
                            ￥{brand.price}(税込)&nbsp;
                          </h4>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div
          id="shopify-section-1662364965370b96b8"
          className="shopify-section"
          style={{ marginTop: "3%" }}
        >
          <div className="title">
            <h2>
              <span>LADIES’ WEAR</span>
            </h2>
          </div>
        </div>

        <div className="mainBox">
          <div className="container-fluid">
            {/* top */}
            <div className="top-container">
              <div className="product-grid">
                {productData
                  .filter((e) => e.id >= 6 && e.id <= 10)
                  .map((brand) => (
                    <div className="product-category" key={brand.id}>
                      <div className="image-container">
                        <Link
                          to={`/shopDetails/${brand.id}`}
                          onClick={handleNavigate}
                        >
                          <img alt="" src={brand.images[0]} />
                        </Link>

                        <h2>{brand.main}</h2>
                        <div className="pp-pre">
                          <h4 className="red-price">
                            ￥{brand.price}(税込)&nbsp;
                          </h4>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div
          id="shopify-section-1662364965370b96b8"
          className="shopify-section"
          style={{ marginTop: "3%" }}
        >
          <div className="title">
            <h2>
              <span>BAG＆COVER</span>
            </h2>
          </div>
        </div>

        <div className="mainBox">
          <div className="container-fluid">
            {/* top */}
            <div className="top-container">
              <div className="product-grid">
                {productData
                  .filter((e) => e.id >= 11 && e.id <= 15)
                  .map((brand) => (
                    <div className="product-category" key={brand.id}>
                      <div className="image-container">
                        <Link
                          to={`/shopDetails/${brand.id}`}
                          onClick={handleNavigate}
                        >
                          <img alt="" src={brand.images[0]} />
                        </Link>

                        <h2>{brand.main}</h2>
                        <div className="pp-pre">
                          <h4 className="red-price">
                            ￥{brand.price}(税込)&nbsp;
                          </h4>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div
          id="shopify-section-1662364965370b96b8"
          className="shopify-section"
          style={{ marginTop: "3%" }}
        >
          <div className="title">
            <h2>
              <span>GOODS</span>
            </h2>
          </div>
        </div>

        <div className="mainBox">
          <div className="container-fluid">
            {/* top */}
            <div className="top-container">
              <div className="product-grid">
                {productData
                  .filter((e) => e.id >= 16 && e.id <= 20)
                  .map((brand) => (
                    <div className="product-category" key={brand.id}>
                      <div className="image-container">
                        <Link
                          to={`/shopDetails/${brand.id}`}
                          onClick={handleNavigate}
                        >
                          <img alt="" src={brand.images[0]} />
                        </Link>

                        <h2>{brand.main}</h2>
                        <div className="pp-pre">
                          <h4 className="red-price">
                            ￥{brand.price}(税込)&nbsp;
                          </h4>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="text-center">
          <img
            alt=""
            src="https://image.salesnauts.com/gossipgolf/img/id5K5jrLSsuQ.jpg"
          />
        </div>
        <div
          id="shopify-section-b47d9df2-d926-46fe-a1e2-44aa689740e5"
          className="shopify-section"
        >
          <div className="custom-field">
            <div className="w1120 mb50">
              <ul className="lineup_flex">
                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/MdwqhTV8XxnL.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/agjFZ32L7Lqf.jpg"
                      alt="有馬ロイヤルゴルフクラブ"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img
                        src="https://image.salesnauts.com/gossipgolf/img/NHmoUWYnALr8.jpg"
                        alt="福岡雷山ゴルフ倶楽部"
                      />
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/MmQoFWXHaFoz.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          id="shopify-section-b47d9df2-d926-46fe-a1e2-44aa689740e5"
          className="shopify-section"
        >
          <div className="custom-field">
            <div className="w1120 mb50">
              <div
                className="page-builder__part page-builder__part--hr"
                style={{ marginTop: "5%" }}
              >
                <hr />
              </div>
              <div className="text-center">
                <img
                  alt=""
                  src="https://image.salesnauts.com/gossipgolf/img/xrdDnADGDX3c.jpg"
                />
              </div>
              <div className="text-center" style={{ marginBottom: "5%" }}>
                <img
                  width="840"
                  height="376"
                  alt=""
                  src="https://image.salesnauts.com/gossipgolf/img/8BDA3KUm3kmV.jpg"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          id="shopify-section-1662364965370b96b8"
          className="shopify-section"
          style={{ marginBottom: "3%" }}
        >
          <div className="title">
            <h2>
              <span>MARK&LONA</span>
            </h2>
          </div>
        </div>

        <div
          id="shopify-section-b47d9df2-d926-46fe-a1e2-44aa689740e5"
          className="shopify-section"
        >
          <div className="custom-field">
            <div className="w1120 mb50">
              <ul className="lineup_flex121">
                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/zyQjv54EN8ja.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    1PIU1UGUALE3 GOLF
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/tXpFFxpYGvYx.jpg"
                      alt="有馬ロイヤルゴルフクラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    archivio
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img
                        src="https://image.salesnauts.com/gossipgolf/img/Zh5GiuJtSoU8.jpg"
                        alt="福岡雷山ゴルフ倶楽部"
                      />
                    </div>
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    BRIEFING
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/QfCfHzkhracz.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    BRIEFING GOLF
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/iN1cWmroRHoh.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    CPG GOLF
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/qWE1kfqTx8gy.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    DESCENTE GOLF
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/MLEJnrikr7gm.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    Eyevol
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/fE432t6UDVyL.jpg"
                      alt="有馬ロイヤルゴルフクラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    G/FORE
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img
                        src="https://image.salesnauts.com/gossipgolf/img/uLD9XGg8e27b.jpg"
                        alt="福岡雷山ゴルフ倶楽部"
                      />
                    </div>
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    gravis golf
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/vmMxhqpmYfeE.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    HORN GARMENT
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/tsRA9umF1d8K.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    Hydro Flask
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/FQ9gWUVtWEMM.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    JUN&ROPÉ
                  </p>
                </li>

                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/93TRxX8bkDgS.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    L4K3
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/pZ2HQVEzGqV1.jpg"
                      alt="有馬ロイヤルゴルフクラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    LADIN
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img
                        src="https://image.salesnauts.com/gossipgolf/img/AnvNRShCk9Fd.jpg"
                        alt="福岡雷山ゴルフ倶楽部"
                      />
                    </div>
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    LUXEAKMPLUS
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/BEHAXXeFsvmM.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    muta
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/ZVdZgqNzXwZc.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    new balance golf
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/WC33HZ7yKVMy.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    Resurrection
                  </p>
                </li>

                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/ge48ZUuhBuMj.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    RUSSELUNO
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/mruTeYMhLEYu.jpg"
                      alt="有馬ロイヤルゴルフクラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    SALVAGE PUBLIC Kolepa
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img
                        src="https://image.salesnauts.com/gossipgolf/img/qYDsMTqWFPP6.jpg"
                        alt="福岡雷山ゴルフ倶楽部"
                      />
                    </div>
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    SY32 GOLF
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/YEpafmMqUw3p.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    TANGRAM
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/rbryH7GERzQQ.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    TFW49
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/byVVsuew6URY.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    V12
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img
                        src="https://image.salesnauts.com/gossipgolf/img/4AdWa6xcxY7G.jpg"
                        alt="福岡雷山ゴルフ倶楽部"
                      />
                    </div>
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    YELLOW LOBSTER
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/oiyfhAy8R7Hc.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    ZERO HALLIBURTON
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/AJzsmEgacE7n.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    ZEROFIT
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/SDMksxWJNWF6.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    北村和義
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          id="shopify-section-b47d9df2-d926-46fe-a1e2-44aa689740e5"
          className="shopify-section"
        >
          <div className="custom-field">
            <div className="w1120 mb50">
              <div className="text-center">
                <img
                  alt=""
                  src="https://image.salesnauts.com/gossipgolf/img/BannPXx2vYFj.jpg"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          id="shopify-section-b47d9df2-d926-46fe-a1e2-44aa689740e5"
          className="shopify-section"
        >
          <div className="custom-field">
            <div className="w1120 mb50">
              <ul className="lineup_flex121">
                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/ZsS1FhMV9XEh.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    BETTINARDI
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/EyhurB9ztxXm.jpg"
                      alt="有馬ロイヤルゴルフクラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    Bushnellgolf
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img
                        src="https://image.salesnauts.com/gossipgolf/img/92MuGuGVMHMb.jpg"
                        alt="福岡雷山ゴルフ倶楽部"
                      />
                    </div>
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    CROSSPUTT
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://image.salesnauts.com/gossipgolf/img/3gXNXHyFNJSY.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    elite grips
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Main;
