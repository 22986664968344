import React, { useContext } from "react";
import { CartContext } from "../Component/CartContext";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/cartpage.css";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const StyledTableContainer = styled(TableContainer)({
  minWidth: 650,
});

const StyledImage = styled("img")({
  width: 112,
  height: 124,
  objectFit: "cover",
});

const StyledTableCell = styled(TableCell)({
  display: "flex",
  alignItems: "center",
  verticalAlign: "middle",
  fontSize: "16px",
  borderRight: "1px dotted #ccc",
});

const CartPage = () => {
  const { cartItems, removeFromCart, updateQuantity } = useContext(CartContext);
  const navigate = useNavigate();

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const toCheckOut = () => {
    if (cartItems.length === 0) {
      alert("チェックアウトに進む前にカートに商品を追加してください。");
      return;
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/checkout");
  };

  const calculateTotal = () => {
    let total = 0;
    cartItems.forEach((item) => {
      const product = getProductById(item.id);
      if (product) {
        const price = parseFloat(product.price);
        total += price * item.quantity || price * 1;
      }
    });
    return total;
  };

  const calculateItemTotal = (itemId, quantity) => {
    const product = getProductById(itemId);
    if (!product) return 0;
    const price = parseFloat(product.price);
    return price * quantity || price * 1;
  };

  const handleInputChange = (id, event, size, color) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (!isNaN(newQuantity) && newQuantity >= 0) {
      updateQuantity(id, newQuantity, size, color);
    }
  };

  return (
    <>
      <div className="main-container">
        <Header />
        <div className="cart-page">
          <div className="basket-title">
            <h1>ショッピングカート</h1>
          </div>
          <div className="cart-total">
            <div className="cart-items">
              {cartItems.length === 0 ? (
                <h3 style={{ color: "black", textAlign: "center" }}>
                  カートは空です！
                </h3>
              ) : (
                <StyledTableContainer component={Paper}>
                  <Table aria-label="shopping cart table">
                    <TableHead>
                      <TableRow>
                        <TableCell>商品名</TableCell>
                        <TableCell align="left">価格</TableCell>
                        <TableCell align="center">数量</TableCell>
                        <TableCell align="left">合計</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cartItems.map((item, index) => {
                        const product = getProductById(item.id);
                        return (
                          <TableRow key={index}>
                            <StyledTableCell component="th" scope="row">
                              <StyledImage
                                src={product.images[0]}
                                alt="Product"
                                style={{
                                  paddingRight: "30px",
                                }}
                              />
                              <div
                                style={{
                                  display: "grid",
                                  width: "70%",
                                }}
                              >
                                <strong>{product.main}</strong>
                                {product.name}
                                <br />
                                <div>サイズ:{item.size}</div>
                                <br />
                                <div
                                  style={{
                                    marginTop: "-3%",
                                    marginBottom: "4%",
                                  }}
                                >
                                  カラー:{item.color}
                                </div>
                                <a
                                  onClick={() =>
                                    removeFromCart(
                                      item.id,
                                      item.size,
                                      item.color
                                    )
                                  }
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                    fontFamily: "Libre Baskerville",
                                    fontSize: "13px",
                                    fontWeight: "400",
                                    marginTop: "-3%",
                                  }}
                                >
                                  削除
                                </a>
                              </div>
                            </StyledTableCell>
                            <TableCell
                              align="left"
                              style={{
                                verticalAlign: "middle",
                                borderRight: "1px dotted #ccc",
                              }}
                            >
                              ￥{product.price}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                verticalAlign: "middle",
                              }}
                            >
                              <input
                                type="number"
                                value={item.quantity || 1}
                                className="cartInput"
                                onChange={(e) =>
                                  handleInputChange(
                                    item.id,
                                    e,
                                    item.size,
                                    item.color
                                  )
                                }
                                min="0"
                              />
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ verticalAlign: "middle", width: "12%" }}
                            >
                              {calculateItemTotal(item.id, item.quantity)}円
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              )}
              {cartItems.length > 0 && (
                <div className="cart-summary">
                  <div className="rightSum">
                    <table className="summary-details">
                      <tbody className="sumTbody">
                        <tr className="sumTr">
                          <th className="subtotalTh">小計</th>
                          <th className="subtotalTxt">{calculateTotal()}円</th>
                        </tr>
                      </tbody>
                    </table>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <button className="checkout-button" onClick={toCheckOut}>
                        ご購入手続きへ
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CartPage;
